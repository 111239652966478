<template>
    <div>
        <b-col align-self="center">
            <b-card
                class="mb-2"

            >
                <b-card>
                    <label style="color:#5b620f;">Mode: </label>
                    <b-button class="cmd-buttons" variant="success" v-b-modal.change-mode>{{devMode}}</b-button>
                </b-card>
                <div v-if="isRunning">Status: Running</div>
                <div v-else>Status: Not Running</div>
                <div>
                    <b-button class="cmd-buttons" variant="success" v-b-modal.run-mist>Run Mist</b-button>
                    <b-button class="cmd-buttons" variant="danger" v-b-modal.stop-mist>Stop Mist</b-button>
                </div>
                <b-modal
                    id="change-mode"
                    ref="modal"
                    centered
                    title="System Mode"
                    @show="resetModeModal"
                    @hidden="resetModeModal"
                    @ok="handleModeOk"
                >
                    <form ref="form" @submit.stop.prevent="handleModeSubmit">
                        <b-form-select v-model="modeSelected" :options="modeOptions"></b-form-select>
                    </form>
                </b-modal>
                <b-modal
                    id="run-mist"
                    ref="modal"
                    centered
                    title="Manual Run"
                    @show="resetRunModal"
                    @hidden="resetRunModal"
                    @ok="handleRunOk"
                >
                    <form ref="form" class="text-center" @submit.stop.prevent="handleRunSubmit">
                        <b-form-group>
                            <b-container>
                                <b-row cols="3">
                                    <b-col class="mb-3">
                                        <label style="max-width: 4rem">Duration:</label>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-input v-model="durAmount"></b-form-input>
                                    </b-col>
                                    <b-col class="mb-3">
                                        <label style="max-width: 4rem">Min(s)</label>
                                    </b-col>
                                    <b-col class="mb-3">
                                        <label style="max-width: 4rem">Mode:</label>
                                    </b-col>
                                    <b-col class="mb-3">
                                        <b-form-select v-model="sprayModeSelected" :options="sprayModeOptions"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <hr>
                            <b-container v-if="sprayModeSelected === 1">
                                <b-row cols="3">
                                    <b-col>
                                        <label>Cycle:</label>
                                    </b-col>
                                    <b-col>
                                        <label>On Time:</label>
                                        <b-form-input v-model="onTime"></b-form-input>
                                        <label>Sec(s)</label>
                                    </b-col>
                                    <b-col >
                                        <label>Off Time:</label>
                                        <b-form-input v-model="offTime"></b-form-input>
                                        <label>Sec(s)</label>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <div v-if="numZones===2">
                                <hr>
                                <b-form-checkbox-group
                                    v-model="selZones"
                                    :options="zoneChecks"
                                    value-field="item"
                                    text-field="name">
                                </b-form-checkbox-group>
                            </div>
                        </b-form-group>
                    </form>
                </b-modal>
                <b-modal
                    id="stop-mist"
                    ref="modal"
                    centered
                    title="Stop Mist"
                    @show="resetStopModal"
                    @hidden="resetStopModal"
                    @ok="handleStopOk"
                >
                    <form ref="form" @submit="handleStopSubmit">
                        <b-form-group
                            label="Please Confirm"
                        >
                        </b-form-group>
                    </form>
                </b-modal>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import roles from '@/roles';
export default {
    name: "CommandCoolMistOther",
    props: ['id', 'devtype', 'uid'],
    data() {
        return {
            runTime: this.$store.getters.deviceCfg.mistDefault,
            runState: null,
            stopMist: false,
            testTime: 5,
            deviceType: '',
            userId: null,
            devId: null,
            testState: null,
            refillAmt: this.$store.getters.deviceCfg.tankSize,
            refillState: null,
            refillDisabled: true,
            socket: null,
            onTime: 30,
            offTime: 30,
            durAmount: 60,
            selZones: [],
            zoneChecks: [],
            numZones: 2,
            sprayModeSelected: 0,
            statusInterval: null,
            sprayModeOptions: [
                {value: 0, text: "Continuous"},
                {value: 1, text: "Cycle"}
            ],
            modeSelected: 0,
            modeOptions: [
                {value: 0, text: 'Auto'},
                {value: 1, text: 'Local'},
                {value: 2, text: 'Off'}
            ]
        }
    },
    created() {
        this.zoneChecks = [];
        this.zoneChecks.push({item: 0, name: "Zone 1", disabled: false });
        this.numZones = this.$store.getters.deviceCfg.numZones;
        if (this.numZones === 2) {
            this.zoneChecks.push({item: 1, name: "Zone 2", disabled: false });
        }
        this.deviceType = this.$props.devtype;
        this.devId = this.$props.id
        this.userId = this.$props.uid;
        this.isDisabled();
        this.statusInterval = setInterval(() => {
            this.$store.dispatch('sendSockMessage', {text: "qStat"});
        }, 5000);
    },
    beforeDestroy() {
        if (this.statusInterval) {
            clearInterval(this.statusInterval);
        }
    },
    computed: {
        isRunning() {
            return this.$store.getters.isSpraying;
        },
        calcTankPercent() {
            const tankAmt = this.$store.getters.otherDevInfo.tank;
            const tankSize = this.$store.getters.deviceCfg.tankSize;
            return (tankAmt / tankSize) * 100.0;
        },
        calcTankDaysLeft() {
            const tankAmt = this.$store.getters.otherDevInfo.tank;
            return Math.trunc(tankAmt * 0.7787);
        },
        devMode() {
            if (isNaN(this.$store.getters.getDeviceSysModeOther)) {
                return this.modeOptions[0].text;
            }
            else {
                return this.modeOptions[this.$store.getters.getDeviceSysModeOther].text;
            }
        }
    },
    methods: {
        isDisabled() {
            if (this.$store.getters.role >= roles.roles.technician)
                this.refillDisabled = null;
            else
                this.refillDisabled = true;
        },
        checkRunFormValidity() {
            // eslint-disable-next-line no-unused-vars
            const valid = this.$refs.form.checkValidity();
            return true;
        },
        resetRunModal() {
            this.runTime = this.$store.getters.deviceCfg.mistDefault;
            this.runState = null
        },
        handleRunOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleRunSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Run Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleRunSubmit() {
            // Exit when the form isn't valid
            if (!this.checkRunFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('run-mist')
            });
            let zoneNum = 0;
            if (this.selZones.length > 0) {
                for (let i = 0; i < this.selZones.length; i++) {
                    zoneNum += (this.selZones[i] + 1);
                }
            }
            else {
                zoneNum = 0;
            }
            this.$store.dispatch('sendSockMessage', {
                text: "runSpray",
                user: this.$store.getters.user,
                dur: parseInt(this.durAmount),
                mode: this.sprayModeSelected,
                zone: zoneNum,
                onTime: parseInt(this.onTime),
                offTime: parseInt(this.offTime)
            });

        },
        resetStopModal() {
            this.stopMist = false;
        },
        // eslint-disable-next-line no-unused-vars
        handleStopOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleStopSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Stop Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleStopSubmit() {
            this.stopMist = true;

            this.$store.dispatch('sendSockMessage', {
                text: "stopSpray"
            });

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('stop-mist')
            });
        },
        checkTestFormValidity() {
            const valid = this.$refs.form.checkValidity();
            let intCheck = false;
            if (!isNaN(parseInt(this.testTime))) {
                if (parseInt(this.testTime) <= 10 && parseInt(this.runTime) > 0)
                    intCheck = true;
            }
            if (valid && intCheck) {
                this.testState = true;
                return true;
            } else {
                this.testState = false;
                return false;
            }
        },
        resetTestModal() {
            this.testTime = 5;
            this.testState = null
        },
        handleTestOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleTestSubmit()
        },
        handleTestSubmit() {
            // Exit when the form isn't valid
            if (!this.checkTestFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('test-mist')
            });
            // TODO: Send Test Mist Command and update Tank Info

        },
        checkRefillFormValidity() {
            const valid = this.$refs.form.checkValidity();
            let intCheck = false;
            if (!isNaN(parseInt(this.refillAmt))) {
                if (parseInt(this.refillAmt) <= this.$store.getters.deviceCfg.tankSize && parseInt(this.refillAmt) > 0)
                    intCheck = true;
            }
            if (valid && intCheck) {
                this.refillState = true;
                return true;
            } else {
                this.refillState = false;
                return false;
            }
        },
        resetRefillModal() {
            this.refillAmt = this.$store.getters.deviceCfg.tankSize;
            this.refillState = null
        },
        handleRefillOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleRefillSubmit()
        },
        handleRefillSubmit() {
            // Exit when the form isn't valid
            if (!this.checkRefillFormValidity()) {
                return
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('refill-tank')
            });
            // TODO: Send Refill Command and update Tank Info

        },
        resetModeModal() {
            this.modeSelected = this.$store.getters.getDeviceSysModeOther;
        },
        // eslint-disable-next-line no-unused-vars
        handleModeOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$store.getters.isDeviceOnline) {
                this.handleModeSubmit();
            }
            else {
                this.$bvToast.toast('Device Appears To Be Offline, Mode Command Won\'t Work Right Now', {
                    title: 'Device Command',
                    variant: 'danger',
                    solid: true
                });
            }
        },
        handleModeSubmit() {
            this.$store.dispatch('sendSockMessage', {text: 'sMode', mode: this.modeSelected  })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('change-mode')
            });
        },
    }
}
</script>

<style scoped>
.cmd-buttons {
    margin: 5px;
    min-width: 120px;
    max-width: 140px;
}
.text-center {
    text-align: center;
    margin: 3px;
}

</style>
