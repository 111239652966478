<template>
    <div class="text-center mb-2"
         style="max-width: 60rem; min-width: 20rem;"
    >
        <b-col>
            <b-card >
                <h6 class="font-weight-bold">Activity Log</h6>
                <hr>
                <b-form-radio-group
                    id="radio-slots"
                    v-model="showSelected"
                    :options="showOptions"
                    @change="optionChange"
                    name="radio-option-slots"
                >
                    <hr>
                </b-form-radio-group>
                <b-tabs content-class="mt-3">
                    <b-tab title="Calendar">
                        <calendar :attributes="attrs" :min-date="getMinDate" :max-date="getMaxDate"></calendar>
                    </b-tab>
                    <b-tab title="List">
                        <b-table striped hover responsive="true"
                                 no-border-collapse
                                 :items="items"
                                 :fields="fields"
                                 :busy="isBusy"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :filter="filter"
                                 :filter-function="customFilter"
                                 :filter-included-fields="filterOn"
                                 @filtered="onFiltered"
                        >
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                        </b-table>
                        <hr>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </b-tab>
                </b-tabs>
                <hr>
                <b-button size="sm" @click="activityDetails" variant="outline-primary">
                    Activity Details
                </b-button>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import eTypes from '@/enumTypes';

export default {
    name: "ActivityLogOther",
    props: ['id', 'devtype', 'uid'],
    created() {
        this.deviceType = this.$props.devtype;
        this.devId = this.$props.id
        this.userId = this.$props.uid;
        let startDate = new Date();
        let endDate = new Date();
        startDate.setHours(0,0,0,0);
        startDate.setDate(1);
        endDate.setHours(0,0,0,0);
        endDate.setDate(endDate.getDate()+1);
        this.$store.dispatch('getDeviceActivityLogOther', {
            devId: this.id,
            userId: this.userId,
            startDate: Math.round(startDate.getTime() / 1000),
            endDate: Math.round(endDate.getTime() / 1000)
        });
        this.syncInterval = setInterval(() => {
            if (this.$store.getters.isActSync) {
                clearInterval(this.syncInterval);
                this.getActLog();
            }
        }, 1000);
        this.updateActInterval = setInterval(() => {
            if (this.$store.getters.refreshActivityState) {
                this.$store.commit('setRefreshActivity', false);
                this.getActLog();
            }
        }, 5000);
    },
    beforeDestroy() {
        if (this.syncInterval) {
            clearInterval(this.syncInterval);
        }
        if (this.updateActInterval) {
            clearInterval(this.updateActInterval);
        }
    },
    data() {
        return {
            items: [],
            fields: [
                'status',
                "date",
                "time"
            ],
            syncInterval: null,
            updateActInterval: null,
            isBusy: true,
            value: '',
            deviceType: '',
            userId: null,
            devId: null,
            context: null,
            attrs: [
                {
                    key: 'today',
                    highlight: {
                        color: 'blue',
                        fillMode: 'light'
                    },
                    dates: new Date()
                }],
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            filter: null,
            filterOn: ['status'],
            showSelected: 0,
            showOptions: [
                {value: 0, text: 'All'},
                {value: 1, text: 'Alert Only'}
            ]
        }
    },
    methods: {
        activityDetails() {
            if (this.deviceType === "InsectControl")
                this.$router.push('/admin/view/activity/' + this.devId);
            else if (this.deviceType === "CoolMist")
                this.$router.push('/admin/view/activity/coolmist/' + this.devId);
        },
        optionChange() {
            if (this.showSelected === 0) {
                this.filter = null;
            } else if (this.showSelected === 1) {
                this.filter = 'Filtered';
            }
        },
        // eslint-disable-next-line no-unused-vars
        customFilter(rowData, filterProp) {
            if (rowData.status === 'Low')
                return true;
            else if (rowData.status === 'Clog')
                return true;
            else if (rowData.status === 'No Flow')
                return true;
            else if (rowData.status === 'Leak')
                return true;
            else
                return false;
        },
        getActLog() {
            this.items = [];
            this.attrs = [{
                key: 'today',
                highlight: {
                    color: 'blue',
                    fillMode: 'light'
                },
                dates: new Date()
            }];
            const actLog = this.getCompActLog;
            for (let i = 0; i < actLog.length; i++) {
                let actItem = {};
                let calItem = {};
                calItem.popover = {};
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG) {
                    actItem.status = "Clog";
                    calItem.popover.label = "Clog";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'orange'
                    actItem._cellVariants = { status: 'warning'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK) {
                    actItem.status = "Leak";
                    calItem.popover.label = "Leak";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'red'
                    actItem._cellVariants = { status: 'danger'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW) {
                    actItem.status = "Low";
                    calItem.popover.label = "Low";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'yellow'
                    actItem._cellVariants = { status: 'warning'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW) {
                    actItem.status = "No Flow";
                    calItem.popover.label = "No Flow";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'orange'
                    actItem._cellVariants = { status: 'warning'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL) {
                    actItem.status = "Normal";
                    calItem.popover.label = "Normal";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'green'
                    actItem._cellVariants = { status: 'success'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_REFILL) {
                    actItem.status = "Refill";
                    calItem.popover.label = "Refill";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'blue'
                    actItem._cellVariants = { status: 'primary'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_SKIPPED) {
                    actItem.status = "Skipped";
                    calItem.popover.label = "Skipped";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'purple'
                    actItem._cellVariants = { status: 'info'};
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_TEST) {
                    actItem.status = "Test";
                    calItem.popover.label = "Test";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'yellow'
                    actItem._cellVariants = { status: 'secondary'};
                }
                const cYear = this.moment(actLog[i].timestamp * 1000).year();
                const cMonth = this.moment(actLog[i].timestamp * 1000).month();
                const cDay = this.moment(actLog[i].timestamp * 1000).date();
                calItem.dates = new Date(cYear, cMonth, cDay);
                actItem.date = this.moment(actLog[i].timestamp * 1000).format("MM/DD/YYYY");
                const curDate = this.moment(new Date).format("MM/DD/YYYY");
                actItem.time = this.moment(actLog[i].timestamp * 1000).format("h:mm A");
                actItem.initiator = actLog[i].src;
                actItem.zone = actLog[i].zone;
                actItem.secs = actLog[i].dur;
                actItem.gal = actLog[i].gal.toFixed(3);
                if (actLog[i].skip === true)
                    actItem.skip = "Y"
                else
                    actItem.skip = "N"
                actItem.reason = actLog[i].reason;
                this.attrs.push(calItem);
                if (actItem.date === curDate)
                    this.items.push(actItem);
            }
            this.isBusy = false;
            this.totalRows = this.items.length;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
            // Update Calendar view
            this.attrs = [{
                key: 'today',
                highlight: {
                    color: 'blue',
                    fillMode: 'light'
                },
                dates: new Date()
            }];
            const actLog = this.getCompActLog;//this.$store.getters.activityLog;
            for (let i = 0; i < actLog.length; i++) {
                let calItem = {};
                calItem.popover = {};
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG) {
                    calItem.popover.label = "Clog";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'orange';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK) {
                    calItem.popover.label = "Leak";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'red';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW) {
                    calItem.popover.label = "Low";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'yellow';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW) {
                    calItem.popover.label = "No Flow";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'orange';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL) {
                    if (this.showSelected === 1)
                        continue;
                    calItem.popover.label = "Normal";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'green';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_REFILL) {
                    if (this.showSelected === 1)
                        continue;
                    calItem.popover.label = "Refill";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'blue';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_SKIPPED) {
                    if (this.showSelected === 1)
                        continue;
                    calItem.popover.label = "Skipped";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'purple';
                }
                if (actLog[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_TEST) {
                    if (this.showSelected === 1)
                        continue;
                    calItem.popover.label = "Test";
                    calItem.popover.visibility = 'focus';
                    calItem.dot = 'yellow';
                }
                const cYear = this.moment(actLog[i].timestamp * 1000).year();
                const cMonth = this.moment(actLog[i].timestamp * 1000).month();
                const cDay = this.moment(actLog[i].timestamp * 1000).date();
                calItem.dates = new Date(cYear, cMonth, cDay);
                this.attrs.push(calItem);
            }
        }
    },
    computed: {
        getMinDate() {
            let curDate = new Date();
            curDate.setDate(1);
            return curDate;
        },
        getMaxDate() {
            return new Date();
        },
        getCompActLog() {
            return this.$store.getters.activityLog;
        },
        isActSync() {
            return !this.$store.getters.isActSync;
        }
    }
}
</script>

<style scoped>

</style>
