<template>
    <div>
        <b-container>
          <b-row
            cols="5"
            align-content="between"
          >
            <b-col cols="12" md="9">
            <h3>Device Details -
                <b-badge v-if="isDevOnline" variant="success">Online</b-badge>
                <b-badge v-else variant="danger">Offline</b-badge>
            </h3>
            </b-col>
            <b-col cols="12" md="3">
              <admin-device-menu></admin-device-menu>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>{{devCustomer}} - {{location}} - {{devId}}</p>
            </b-col>
          </b-row>
        </b-container>
        <hr>
        <div id="app">
            <div class="page" v-if="!isSynced">
                <b-spinner class="spinner" variant="primary" key="primary"></b-spinner>
            </div>
            <b-container v-if="isSynced">
                <b-row
                    cols="1"
                    cols-sm="1"
                    cols-md="2"
                    cols-lg="3"
                    align-v="start"
                    align-h="between"
                >
                    <next-spray :id="devId" :uid="userId"></next-spray>
                    <command-mod :id="devId" :uid="userId" v-if="devType === 'InsectControl'"></command-mod>
                    <cmd-mod-cool-mist :id="devId" :uid="userId" v-if="devType === 'CoolMist'"></cmd-mod-cool-mist>
                    <activity-graph :id="devId" :uid="userId" v-if="devType === 'InsectControl'"></activity-graph>
                    <activity-log :id="devId" :uid="userId" :devtype="devType"></activity-log>
                    <spray-schedule :id="devId" :uid="userId" v-if="devType === 'InsectControl'"></spray-schedule>
                    <spray-sch-cool-mist :id="devId" :uid="userId" v-if="devType === 'CoolMist'"></spray-sch-cool-mist>
                    <suspend-schedule :id="devId" :uid="userId"></suspend-schedule>
                    <geo-data :id="devId" :uid="userId"></geo-data>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import AdminDeviceMenu from "@/components/AdminDeviceMenu";
import NextSpray from "@/components/modules/NextSprayOther";
import Command from "@/components/modules/CommandOther";
import CommandCoolMist from "@/components/modules/CommandCoolMistOther";
import ActivityLog from "@/components/modules/ActivityLogOther";
import SpraySchedule from "@/components/modules/SprayScheduleOther";
import SpraySchCoolMist from "@/components/modules/SpraySchCoolMistOther";
import SuspendSchedule from "@/components/modules/SuspendScheduleOther";
import GeoData from "@/components/modules/GeoDataOther";
import ActivityGraph from "@/components/modules/ActivityGraphOther";
import Vue from "vue";

export default {
    name: "DeviceDetailsOther",
    components: {
        adminDeviceMenu: AdminDeviceMenu,
        nextSpray: NextSpray,
        commandMod: Command,
        cmdModCoolMist: CommandCoolMist,
        activityLog: ActivityLog,
        spraySchedule: SpraySchedule,
        spraySchCoolMist: SpraySchCoolMist,
        suspendSchedule: SuspendSchedule,
        geoData: GeoData,
        activityGraph: ActivityGraph
    },
    data() {
        return {
            devId: null,
            userId: null,
            refreshInterval: null,
            pingInterval: null,
            location: ""
        }
    },
    computed: {
        isSynced() {
            return this.$store.getters.isDeviceSync;
        },
        isDevOnline() {
            return this.$store.getters.isDeviceOnline;
        },
        devType () {
            return this.$store.getters.getCurDevType;
        },
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
              return "";
            else
              return this.$store.getters.deviceSys.sysName;
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.userId = this.$store.getters.adminSelectedDev.userId;
        Vue.http.get('ugeo/loc/street?id=' + this.devId + '&userId=' + this.userId).then(response => {
          return response.json();
        }).then(res => {
          this.location = res.street;
        }).catch(err => {
          console.log(err);
        });
        this.$store.dispatch('getOtherDevInfo', {
            id: this.devId,
            userId: this.userId
        });
        this.$store.dispatch('getDeviceCfgOther', {
            devId: this.devId,
            userId: this.userId
        });
        this.$store.dispatch('getDeviceSysOther', {
            devId: this.devId,
            userId: this.userId
        });
        this.$store.dispatch('getCompList', this.devId);
        this.$store.dispatch('getAdminDeviceGeo', {
            id: this.devId,
            userId: this.userId
        });
        this.$store.dispatch('getDeviceScheduleOther', {
            id: this.devId,
            userId: this.userId
        });
        this.$store.dispatch('getDeviceSuspendOther', {
            id: this.devId,
            userId: this.userId
        });
        this.refreshInterval = setInterval(() => {
            this.$store.dispatch('checkDeviceSyncOther');
            if (this.$store.getters.isDeviceSync) {
                this.$store.dispatch('joinDeviceRoom', this.devId);
                clearInterval(this.refreshInterval);
            }
        }, 1000);
        this.pingInterval = setInterval(() => {
            if (!this.$store.getters.isDeviceOnline) {
                this.$store.dispatch('sendSockMessage', {text: 'ping'});
            }
            else {
                let curTime = Date.now();
                let lastPing = this.$store.getters.getLastDevicePingTime;
                let elapsedTime = Math.round(Math.abs((curTime - lastPing) / 1000));
                if (elapsedTime > 59) {
                    this.$store.dispatch('sendSockMessage', {text: 'ping'});
                }
                if (elapsedTime > 69) {
                    this.$store.commit('setDeviceOnlineStatus', false);
                }
            }
        }, 10000);
    },
    beforeDestroy() {
        this.$store.dispatch('leaveDeviceRoom', this.devId);
        this.$store.commit('setDeviceOnlineStatus', false);
        this.$store.commit('setActiveDevice', '9x9x9x');
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }
    },
    destroyed() {

    }
}
</script>

<style scoped>
#app {
    text-align: center;
    color: #2c3e50;
}
.page {
    /*position: absolute;*/
    /*background: rgba(0,0,0,0.3);*/
    /*z-index: 25;*/
    /*width: 100%;*/
    /*height: 100%;*/
}
.spinner {
    position: center;
    /*top: 100%;*/
    margin-top: 10rem;
}
</style>
