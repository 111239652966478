<template>
    <div class="text-center mb-2"
         style="min-width: 20rem"
    >
        <b-col >
            <b-card class="md-2">
                <h6 class="font-weight-bold">Suspend Sprays</h6>
                <hr>
                <b-table striped hover small responsive="true" :items="items" :fields="fields">
                    <template #thead-top>
                        <b-tr>
                            <b-th variant="secondary" colspan="2">From</b-th>
                            <b-th colspan="1"></b-th>
                            <b-th variant="secondary" colspan="2">To</b-th>
                        </b-tr>
                    </template>
                </b-table>
                <hr>
                <b-button @click="editSuspend" variant="outline-primary">
                    <b-icon icon="pencil"></b-icon> Edit
                </b-button>
            </b-card>
        </b-col>
    </div>
</template>

<script>
export default {
    name: "SuspendScheduleOther",
    props: ['id', 'uid'],
    data() {
        return {
            items: [],
            devId: null,
            userId: null,
            fields: [
                {key: "fromDate", label: 'Date'},
                {key: "fromTime", label: 'Time'},
                {key: "blankSpace1", label: ''},

                {key: "toDate", label: 'Date'},
                {key: "toTime", label: 'Time'}
            ],
            refreshSus: null,
            syncStatDate: Math.round(Date.now() / 1000)
        }
    },
    created() {
        this.devId = this.$props.id
        this.userId = this.$props.uid;
        this.updateSus();
        this.refreshSus = setInterval(() => {
            this.$store.dispatch('getDeviceSusStatusOther', {
                id: this.devId,
                userId: this.userId
            }).then(() => {
                if (this.$store.getters.getSusSyncCode !== 1) {
                    if (this.$store.getters.getSusSyncTime !== this.syncStatDate) {
                        this.syncStatDate = this.$store.getters.getSusSyncTime;
                        this.$store.dispatch('getDeviceSuspendOther', {
                            id: this.devId,
                            userId: this.userId
                        }).then(() => {
                            this.updateSus();
                        });
                    }
                }
            }).catch(() => {

            });
        }, 30000);
    },
    beforeDestroy() {
        if (this.refreshSus) {
            clearInterval(this.refreshSus);
        }
    },
    methods: {
        updateSus() {
            this.items = [];
            const sus = this.$store.getters.deviceSus;
            for (let i = 0; i < sus.length; i++) {
                let sItem = {};
                if (i % 2 === 0) {
                    sItem._rowVariant = 'danger';
                }
                // else {
                //     sItem._rowVariant = 'secondary';
                // }
                sItem.fromDate = this.moment(sus[i].sTime * 1000).format("MM/DD");
                sItem.fromTime = this.moment(sus[i].sTime * 1000).format("h:mm A");
                sItem.toDate = this.moment(sus[i].eTime * 1000).format("MM/DD");
                sItem.toTime = this.moment(sus[i].eTime * 1000).format("h:mm A");
                this.items.push(sItem);
            }
        },
        editSuspend() {
            this.$router.push('/admin/editor/suspend/' + this.id);
        }
    }
}
</script>

<style scoped>

</style>
